import React from "react"
import { FaLinkedinIn, FaInstagram, FaTwitter } from "react-icons/fa"

export default function Footer() {
  return (
    <footer className="footer">
      © {new Date().getFullYear()} - Robin de Zeeuw
      {/* {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a> */}
      <div className="social-icons">
        <a
          href="http://www.linkedin.com/in/janwillemdezeeuw/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaLinkedinIn className="social-icon" aria-label="linkedin" />
        </a>
        <a
          href="http://www.instagram.com/jwdezeeuw/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaInstagram className="social-icon" aria-label="instagram" />
        </a>
        <a
          href="http://www.twitter.com/jwdezeeuw"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaTwitter className="social-icon" aria-label="twitter" />
        </a>
      </div>
    </footer>
  )
}
