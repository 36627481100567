import React from 'react'
import Layout from "../../components/layout"
import BackButton from '../../components/BackBtn'
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"

export default function Projecten() {
    return (
        <Layout>
            <Seo title="Projecten" description="Bekijk hier al mijn fotoprojecten"/>

            <main className="main-content main-projecten">
                <div className="title-container">
                   <h1 className="main-title">Projecten</h1>
                   <BackButton path="../" text="Terug" />
                </div>
                <p className="main-description">
                    
                </p>
                <div className="card-container">
                    <Link to="../Projecten/project1" className="card__link">
                        <div className="card">
                        <StaticImage
                            src="../../images/thumbs/huizen.jpg"
                            className="card-img"
                            placeholder="blurred"
                            width={600}
                            height={400}
                            formats={["auto", "webp", "avif"]}
                            alt="Project 1: Als huizen konden praten"
                            transformOptions={{ fit: "cover", cropFocus: "attention" }}
                            />
                            <h5 className="card__title">Project 1: Als huizen konden praten</h5>
                        </div>
                    </Link>

                    <Link to="../Projecten/project2" className="card__link"> 
                        <div className="card">
                            <StaticImage 
                                src="../../images/thumbs/gas-and-oil.jpg"
                                alt="Project 2: Gas and oil by night" 
                                className="card-img"
                                />
                            <h5 className="card__title">Project 2: Gas & oil by night</h5>
                        </div>
                    </Link>
                    <Link to="../Projecten/project3" className="card__link">
                        <div className="card">
                            <StaticImage 
                                src="../../images/thumbs/utrecht.jpg"
                                alt="Project 3: Utrecht" 
                                className="card-img"
                                />
                            <h5 className="card__title">Project 3: Utrecht</h5>
                        </div>
                    </Link>
                    <Link to="../Projecten/project4" className="card__link">
                        <div className="card">
                            <StaticImage 
                                src="../../images/thumbs/straatwerk.jpg"
                                alt="Project 4: Straatwerk" 
                                className="card-img"
                                />
                            <h5 className="card__title">Project 4: Straat-Werk</h5>
                        </div>
                    </Link>
                </div>
            </main>
        </Layout>
    )
}
