import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Navbar from '../components/Navbar'
import Header from "./Header"
import Footer from './Footer'
// import "./layout.css"
import "../main.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => setShowMenu(!showMenu)

  return (
    <>
      <Header 
        toggleMenu={toggleMenu} 
        siteTitle={data.site.siteMetadata?.title || `Title`} />
      {showMenu ? <Navbar className="navbar"/> : 
      <>
        <main className="main">{children}</main> 
        <Footer />
      </> }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
