import React from "react";
import { Link } from 'gatsby'
import '../main.css'

export default function Navbar() {
    
    return (
       <nav className="navbar">
           <Link activeClassName="active" to="../../" className="navbar-item">Home</Link>
           <Link activeClassName="active" to="../../Portfolio" className="navbar-item">Portfolio</Link>
           <Link activeClassName="active" to="../../Documentaires" className="navbar-item">Documentaires</Link>
           <Link activeClassName="active" to="../../Projecten" className="navbar-item">Projecten</Link>
           <Link activeClassName="active" to="../../about" className="navbar-item">Over mij</Link>
           <Link activeClassName="active" to="../../contact" className="navbar-item">Contact</Link>
       </nav>
    )
}