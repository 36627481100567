import React from "react"
import NavbarBig from "./NavbarBig"
import "../main.css"
import { Sling as Hamburger } from "hamburger-react"
import { Link } from "gatsby"

function Header(props) {
  return (
    <header className="header">
      <div className="menu-small">
        <Hamburger size={25} onToggle={props.toggleMenu} />
      </div>
      <div className="logo-container">
        <Link to="/" className="title-link">
          <h1 className="header-title">Jan Willem de Zeeuw</h1>
          
        </Link>
      </div>
      <div className="nav-container">
        <NavbarBig />
      </div>
    </header>
  )
}

export default Header
